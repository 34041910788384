function popupComponent() {
    var popups = $('.popup-container').toArray();
    var openPopup = function (index) {
        var _a, _b, _c;
        var popup = popups[index];
        if (popup) {
            var cookieName_1 = 'popup-' + popup.dataset.code;
            var canShowPopup = !checkCookie(cookieName_1) &&
                !window.location.href.includes('checkout') &&
                !window.location.href.includes('login');
            if (canShowPopup) {
                blockPageScroll();
                var closePopup_1 = function () {
                    restorePageScroll();
                    createCookie(cookieName_1, 'true');
                    popup.remove();
                    setTimeout(function () { return openPopup(index + 1); }, 10000);
                };
                (_a = popup.querySelector('.popup-close')) === null || _a === void 0 ? void 0 : _a.addEventListener('click', function (event) {
                    event.preventDefault();
                    closePopup_1();
                });
                (_b = popup.querySelector('.popup-image-link a[href*="#"]')) === null || _b === void 0 ? void 0 : _b.addEventListener('click', function (event) {
                    setTimeout(function () {
                        closePopup_1();
                        if (isIOS()) {
                            requestAnimationFrame(function () { return window.dispatchEvent(new HashChangeEvent("hashchange", {
                                oldURL: window.location.href,
                                newURL: window.location.href
                            })); });
                        }
                    }, 1000);
                });
                var link_1 = popup.querySelector('a.btn');
                if (link_1) {
                    (_c = popup.querySelector('img')) === null || _c === void 0 ? void 0 : _c.addEventListener('click', function (event) {
                        event.preventDefault();
                        closePopup_1();
                        window.location.href = link_1.href;
                    });
                    link_1.addEventListener('click', function (event) {
                        event.preventDefault();
                        closePopup_1();
                        window.location.href = link_1.href;
                    });
                }
                popup.classList.remove('hide');
            }
            else {
                setTimeout(function () { return openPopup(index + 1); }, 10000);
            }
        }
    };
    setTimeout(function () { return openPopup(0); }, 15000);
}
